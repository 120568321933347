/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "This scandinavian modern home sits wonderfully close to the rolling green parks of\nLake Como. The light woodwork and airy feel from the windows brings a sense of cheer\nto the hom."), "\n", React.createElement(_components.p, null, "The heritage architecture and new-world design of this 4 bedroom 3 bath home will make its owners many future memories."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
